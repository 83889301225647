import { gql } from 'graphql-tag'
import { BDESE_SECTION_FRAGMENT } from './bdeseFragment'

export const UPDATE_BDESE_SECTION_MUTATION = gql`
  ${BDESE_SECTION_FRAGMENT}
  mutation UPDATE_BDESE_SECTION_MUTATION(
    $id: String!
    $sectionId: String!
    $newContent: String!
    $newTitle: String!
  ) {
    updateBdeseSection(
      id: $id
      sectionId: $sectionId
      newContent: $newContent
      newTitle: $newTitle
    ) {
      ...BdeseSectionFields
    }
  }
`
