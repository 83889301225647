import { useCustomizationStore } from './customization'
import { useCurrentUserStore } from './currentUser'
import { useTimeDimensionStore } from '~/stores/timeDimension'
import { useScopeStore } from '~/stores/scope'
import { useCubeStore } from '~/stores/cube'
import { useAnalyticsStore } from '~/stores/analytics'
import { getCustomFiltersFromStandardFilters } from '~/services/filters'
import {
  DataType,
  type Filter,
  type ScopeFilter,
  type StandardFilterOption
} from '~/types/analytics'
import { type Dimension, FilterOperator } from '~/types/cube'
import { analyticsGetOptions } from '~/services/analytics'
import {
  replaceRouteQueryParam,
  getDashboardFromRoute
} from '~/services/router'
import { QUERY_PARAMETERS } from '~/types/queryParameters'
import { DIMENSION_TITLES } from '~/constants/analytics'
import { DEFAULT_MAIN_CUBES } from '~/constants/cube'
import {
  getCubeFromMeasureOrDimensionName,
  getDataTypeFromMemberTitle
} from '~/services/explore'
import { formatMemberValue } from '~/services/analyticsFormat'
import { CustomizationField } from '~/types/customization'
import { Dashboard } from '~/constants/homeModules'
import { FETCH_DASHBOARD_DEFAULT_FILTERS_QUERY } from '~/gql/dashboardDefaultFilters/fetchDashboardDefaultFilters'
import type {
  DefaultFiltersByDashboard,
  FilterWithId,
  StandardFilters
} from '~/types/filter'
import { UPDATE_DASHBOARD_DEFAULT_FILTERS_MUTATION } from '~/gql/dashboardDefaultFilters/updateDashboardDefaultFilters'
import { ScopeType } from '~/types/permissions'
import { FormatContext } from '~/types/analyticsFormat'

interface State {
  areFiltersOpen: boolean
  standardFilters: StandardFilters
  customFilters: Filter[]
  defaultFiltersByDashboard: DefaultFiltersByDashboard
  defaultFilterIdsToRemove: string[]
}

const extractOptionsFromDimension = async (
  dimension: Dimension,
  dateRangeFilter: Filter,
  scopeType: string
) => {
  const filters = [dateRangeFilter]

  try {
    const categories = await analyticsGetOptions(
      dimension.shortTitle,
      filters,
      scopeType
    )

    return categories
      .map(c => ({
        value: c[dimension.name],
        label: formatMemberValue(
          c[dimension.name]!,
          dimension.meta.format,
          FormatContext.DEFAULT
        )
      }))
      .filter(x => x.value !== null) as StandardFilterOption[]
  } catch (err) {
    console.log(`Unable to get options for dimension ${dimension} :`, err)
    return []
  }
}

export const useFiltersStore = defineStore('filters', {
  state: (): State => ({
    areFiltersOpen: false,
    standardFilters: {},
    customFilters: [],
    defaultFiltersByDashboard: {},
    defaultFilterIdsToRemove: []
  }),
  getters: {
    usableStandardFilters(state: State): string[] {
      const standardFiltersDimensions = Object.keys(state.standardFilters)
      const { usableDataTypes } = useAnalyticsStore()

      const mainDataType = usableDataTypes.includes(DataType.EMPLOYEE)
        ? DataType.EMPLOYEE
        : usableDataTypes[0]

      const usableStandardFiltersDimensions = standardFiltersDimensions.filter(
        title => mainDataType === getDataTypeFromMemberTitle(title)
      )
      return usableStandardFiltersDimensions
    },
    displayedStandardFilters(state: State): string[] {
      return this.usableStandardFilters
        .filter(title => state.standardFilters[title]!.options.length > 0)
        .slice(0, 3)
    },
    standardFiltersWithSelectedValues(state: State): string[] {
      return Object.entries(state.standardFilters).reduce(
        (standardFiltersWithSelectedValues, [title, { values }]) =>
          values && values.length > 0
            ? [...standardFiltersWithSelectedValues, title]
            : [...standardFiltersWithSelectedValues],
        [] as string[]
      )
    },
    scopeFilters(_: State): ScopeFilter[] {
      const { scope } = useCurrentUserStore()
      const { scopeType } = useScopeStore()
      return scopeType === ScopeType.USER_SCOPE
        ? (scope && scope.filters) || []
        : []
    },
    usableScopeFilters(_: State): ScopeFilter[] {
      const { usableDataTypes } = useAnalyticsStore()
      return this.scopeFilters.filter(scopeFilter =>
        usableDataTypes.includes(scopeFilter.dataType)
      )
    },
    dashboardDefaultFilters(state: State): FilterWithId[] {
      const router = useRouter()
      const dashboard = getDashboardFromRoute(router.currentRoute.value)
      if (!dashboard) return []
      return state.defaultFiltersByDashboard[dashboard] ?? []
    },
    appliedDashboardDefaultFilters(state: State): FilterWithId[] {
      return this.dashboardDefaultFilters.filter(
        filter => !state.defaultFilterIdsToRemove.includes(filter.id)
      )
    },
    getDefaultFiltersByDashboard(
      state: State
    ): (Dashboard: Dashboard) => Filter[] {
      return dashboard =>
        state.defaultFiltersByDashboard[dashboard]?.map(filter => ({
          member: filter.member,
          operator: filter.operator,
          values: filter.values
        })) ?? []
    },
    hasDifferentFiltersFromDefault(state: State): boolean {
      return (
        state.customFilters.length > 0 ||
        state.defaultFilterIdsToRemove.length > 0 ||
        Object.values(state.standardFilters).some(
          standardFilter => standardFilter.values.length > 0
        )
      )
    },
    allAppliedFilters(state: State): Filter[] {
      return [
        ...state.customFilters,
        ...getCustomFiltersFromStandardFilters(state.standardFilters),
        ...this.appliedDashboardDefaultFilters.map(filter => ({
          member: filter.member,
          operator: filter.operator,
          values: filter.values
        }))
      ]
    }
  },
  actions: {
    toggleFilters() {
      this.areFiltersOpen = !this.areFiltersOpen
    },
    async getStandardFiltersOptions() {
      const { selectedPeriod } = useTimeDimensionStore()
      const { getCustomizationByField } = useCustomizationStore()

      if (!(selectedPeriod.dateRange.length === 2)) return

      const dateRangeFilter = {
        member: DIMENSION_TITLES.DATE,
        operator: FilterOperator.IN_DATE_RANGE,
        values: selectedPeriod.dateRange
      }

      const { dimensionsMeta } = useCubeStore()

      const STANDARD_FILTER_KEYS = [
        CustomizationField.EMPLOYEE_STANDARD_1,
        CustomizationField.EMPLOYEE_STANDARD_2,
        CustomizationField.EMPLOYEE_STANDARD_3,
        CustomizationField.RECRUITMENT_STANDARD_1,
        CustomizationField.RECRUITMENT_STANDARD_2,
        CustomizationField.RECRUITMENT_STANDARD_3
      ]

      const standardFilters = STANDARD_FILTER_KEYS.map(field =>
        getCustomizationByField(field)
      ).map(
        dimensionShortTitle =>
          dimensionsMeta!.find(
            dimension =>
              dimension.shortTitle === dimensionShortTitle &&
              DEFAULT_MAIN_CUBES.includes(
                getCubeFromMeasureOrDimensionName(dimension.name)
              )
          )!
      )

      const { scopeType } = useScopeStore()

      const standardFiltersOptions = await Promise.all(
        standardFilters!.map(dimension =>
          extractOptionsFromDimension(dimension, dateRangeFilter, scopeType)
        )
      )

      standardFilters!.forEach((dimension, index) => {
        if (this.standardFilters[dimension.shortTitle]) {
          this.standardFilters[dimension.shortTitle]!.options =
            standardFiltersOptions[index]!
        } else {
          this.standardFilters[dimension.shortTitle] = {
            options: standardFiltersOptions[index]!,
            values: []
          }
        }
      })
    },
    async setStandardFilter({
      title,
      values,
      shouldModifyUrlParams = true
    }: {
      title: string
      values: StandardFilterOption[]
      shouldModifyUrlParams?: boolean
    }) {
      this.standardFilters[title]!.values = [...values]

      shouldModifyUrlParams && (await this.changeRouteFilterQueryParams())
    },
    async setCustomFilters({
      values,
      shouldModifyUrlParams = true
    }: {
      values: Filter[]
      shouldModifyUrlParams: boolean
    }) {
      this.customFilters = [...values]
      shouldModifyUrlParams && (await this.changeRouteFilterQueryParams())
    },
    async addCustomFilter({
      filter: { member, operator, values },
      shouldModifyUrlParams = true
    }: {
      filter: Filter
      shouldModifyUrlParams?: boolean
    }) {
      this.customFilters = [...this.customFilters, { member, operator, values }]

      if (this.customFilters.length > 0) {
        this.replaceStandardFiltersWithCustomFilters()
      }

      shouldModifyUrlParams && (await this.changeRouteFilterQueryParams())
    },
    async deleteCustomFilter({
      index,
      shouldModifyUrlParams = true
    }: {
      index: number
      shouldModifyUrlParams?: boolean
    }) {
      const customFilters = this.customFilters.slice(0)
      customFilters.splice(index, 1)
      this.customFilters = customFilters

      shouldModifyUrlParams && (await this.changeRouteFilterQueryParams())
    },
    async updateCustomFilter({
      index,
      filter: { member, operator, values },
      shouldModifyUrlParams = true
    }: {
      index: number
      filter: Filter
      shouldModifyUrlParams?: boolean
    }) {
      const customFilters = this.customFilters.slice(0)
      customFilters[index] = { member, operator, values }
      this.customFilters = customFilters

      shouldModifyUrlParams && (await this.changeRouteFilterQueryParams())
    },
    resetStandardFilters() {
      this.standardFilters = Object.entries(this.standardFilters).reduce(
        (standardFiltersWithEmptyValues, [title, filterContent]) => ({
          ...standardFiltersWithEmptyValues,
          [title]: { ...filterContent, values: [] }
        }),
        {} as StandardFilters
      )
    },
    replaceStandardFiltersWithCustomFilters() {
      this.customFilters = [
        ...getCustomFiltersFromStandardFilters(this.standardFilters),
        ...this.customFilters
      ]

      this.resetStandardFilters()
    },
    async changeRouteFilterQueryParams() {
      const allDimensionFilters = [
        ...getCustomFiltersFromStandardFilters(this.standardFilters),
        ...this.customFilters
      ]

      await replaceRouteQueryParam([
        [
          QUERY_PARAMETERS.DASHBOARD_FILTERS,
          allDimensionFilters.length > 0
            ? encodeURIComponent(JSON.stringify(allDimensionFilters))
            : undefined
        ],
        [
          QUERY_PARAMETERS.DASHBOARD_DEFAULT_FILTER_IDS_TO_REMOVE,
          this.defaultFilterIdsToRemove.length > 0
            ? encodeURIComponent(JSON.stringify(this.defaultFilterIdsToRemove))
            : undefined
        ]
      ])
    },
    async loadDashboardDefaultFilters() {
      const app = useNuxtApp()

      const defaultFiltersByDashboard = (
        await app.$apiGqlClient.query({
          query: FETCH_DASHBOARD_DEFAULT_FILTERS_QUERY
        })
      ).data.getDashboardDefaultFilters as {
        dashboard: Dashboard
        filter: FilterWithId
      }[]

      this.defaultFiltersByDashboard = defaultFiltersByDashboard.reduce(
        (acc, { dashboard, filter }) => ({
          ...acc,
          [dashboard]: [...(acc[dashboard] ?? []), filter]
        }),
        {} as DefaultFiltersByDashboard
      )
    },
    prepareDashboardDefaultFilters() {
      const queryParam =
        useRoute().query[
          QUERY_PARAMETERS.DASHBOARD_DEFAULT_FILTER_IDS_TO_REMOVE
        ]

      this.defaultFilterIdsToRemove = (
        queryParam ? JSON.parse(decodeURIComponent(queryParam as string)) : []
      ) as string[]

      if (this.appliedDashboardDefaultFilters.length > 0) {
        this.replaceStandardFiltersWithCustomFilters()
        this.areFiltersOpen = true
      }
    },
    async cleanDefaultFilterIdsToRemove() {
      this.defaultFilterIdsToRemove = []

      await this.changeRouteFilterQueryParams()
    },
    async resetFiltersToDefault() {
      this.resetStandardFilters()
      await this.setCustomFilters({
        values: [],
        shouldModifyUrlParams: false
      })
      this.defaultFilterIdsToRemove = []

      await this.changeRouteFilterQueryParams()
    },
    async removeDashboardDefaultFilter(filterId: string) {
      if (this.defaultFilterIdsToRemove.includes(filterId)) return

      this.defaultFilterIdsToRemove = [
        ...this.defaultFilterIdsToRemove,
        filterId
      ]
      await this.changeRouteFilterQueryParams()
    },
    async updateDashboardDefaultFilter(id: string, filter: Filter) {
      await this.addCustomFilter({ filter, shouldModifyUrlParams: false })
      await this.removeDashboardDefaultFilter(id)
    },
    async setDefaultFilters() {
      const app = useNuxtApp()

      const dashboard = getDashboardFromRoute(useRoute())

      if (!dashboard) return

      const updatedDashboardDefaultFilters = (
        await app.$apiGqlClient.query({
          query: UPDATE_DASHBOARD_DEFAULT_FILTERS_MUTATION,
          variables: {
            dashboard,
            newFilters: [
              ...this.appliedDashboardDefaultFilters.map(filter => ({
                member: filter.member,
                operator: filter.operator,
                values: filter.values
              })),
              ...this.customFilters,
              ...getCustomFiltersFromStandardFilters(this.standardFilters)
            ]
          }
        })
      ).data.updateDashboardDefaultFilters as {
        dashboard: Dashboard
        filter: FilterWithId
      }[]

      this.defaultFiltersByDashboard[dashboard] =
        updatedDashboardDefaultFilters.map(({ filter }) => filter)

      await this.resetFiltersToDefault()
    }
  }
})
