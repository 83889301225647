export enum FormatContext {
  DEFAULT = 'DEFAULT',
  STAT = 'STAT',
  EVOLUTION = 'EVOLUTION',
  CHART = 'CHART',
  EXPORT = 'EXPORT',
  KPI = 'KPI'
}

export enum MemberFormat {
  NUMBER_0 = 'number0',
  NUMBER_1 = 'number1',
  NUMBER_2 = 'number2',
  PERCENT = 'percent',
  // PERCENT_0 is currently never used by cube but only by the formatMixin
  PERCENT_0 = 'percent0',
  CURRENCY = 'currency',
  CURRENCY_EXACT = 'currencyExact',
  CURRENCY_EXACT_0 = 'currencyExact0',
  DATE = 'date',
  BOOLEAN = 'boolean',
  NAME = 'name',
  DAYS = 'days',
  AGE_YEARS = 'ageYears',
  AGE_YEARS_MONTHS_DAYS = 'ageYearsMonthsDays',
  TRANSLATION_KEYS = 'translationKeys',
  DIMENSION_TITLES = 'dimensionTitles'
}
