import {
  analyticsGetQuery,
  buildCompleteQuery,
  compareValuesAlphabetically
} from './analytics'
import {
  getCustomTableData,
  getTableCell,
  addColumnsData,
  buildBodyRowsData,
  getColumnValues,
  getContent,
  getDimensionValueFromResultSetItem,
  getRowsWithDimensionValues,
  getDefaultColumnValue,
  removeEmptyBodyRows,
  processResultSetForTableData
} from './table'
import { getHtmlFromCustomTable, parseHTMLFromString } from './html'
import { formatDate2, isDateBefore } from './date'
import {
  getFiscalFormat,
  getFiscalYearDateRange,
  getPeriodFromDateRange,
  getYearsFromDateRange
} from './period'
import {
  getDimensionFromTitleAndMainCube,
  getMainCubeFromQuery,
  getMeasureFromTitle
} from './explore'
import { formatMemberValue } from './analyticsFormat'
import { DIMENSION_TITLES } from '~/constants/analytics'
import {
  QueryTimeType,
  type Filter,
  type DimensionValue
} from '~/types/analytics'
import {
  type BdeseQuery,
  type Bdese,
  type TableOfContent,
  SectionTitleLevel,
  type BdeseBaseSection,
  ExplorerChartContentType,
  BdeseTemplate,
  type BdeseParams
} from '~/types/bdese'
import { CREATE_BDESE_MUTATION } from '~/gql/legalReports/bdese/createBdeseMutation'
import { ScopeType } from '~/types/permissions'
import {
  ATTACHMENT_ZONE_ATTRIBUTES,
  BDESE_SCOPE_MAIN_DIMENSION_TITLE,
  IMAGE_ATTRIBUTES,
  TABLE_ATTRIBUTES
} from '~/constants/bdese'
import type { TableCell, TableData } from '~/types/table'
import type { DateRangeOption } from '~/types/dateRange'
import type {
  PartialQuery,
  Query,
  ResultSet,
  ResultSetItem
} from '~/types/query'
import { GET_BDESE_TEMPLATE_SECTION } from '~/gql/legalReports/bdese/getBdeseTemplateSection'
import { TagTheme } from '~/types/tagTheme'
import type { Dimension } from '~/types/cube'
import type { FullPeriod } from '~/types/timeDimension'
import { FormatContext } from '~/types/analyticsFormat'

export const formatSectionTitle = (title: string, order: number) =>
  `${order + 1}. ${title}`

export const getBdeseRealizedPeriod = (
  bdeseYear: number,
  realizedYearsNumber: number,
  isFiscal: boolean
): [string, string] => {
  let startDate: Date
  let endDate: Date

  if (isFiscal) {
    startDate = getFiscalYearDateRange(bdeseYear - realizedYearsNumber)[0]
    endDate = getFiscalYearDateRange(bdeseYear - 1)[1]
  } else {
    startDate = new Date(bdeseYear - realizedYearsNumber, 0, 1)
    endDate = new Date(bdeseYear - 1, 11, 31)
  }
  return [
    formatDate2(startDate),
    formatDate2(isDateBefore(new Date(), endDate) ? new Date() : endDate)
  ]
}

export const getBdeseRealizedYears = (
  bdeseYear: number,
  realizedYearsNumber: number
): number[] => {
  const realizedYearsIndex = Array.from(
    { length: realizedYearsNumber },
    (_, index) => -index - 1
  ).toReversed()

  return realizedYearsIndex.map(yearIndex => bdeseYear + yearIndex)
}

export const getBdeseTrendYears = (
  bdeseYear: number,
  trendYearsNumber: number
): number[] => {
  const trendYearsIndex = Array.from(
    { length: trendYearsNumber },
    (_, index) => index
  )

  return trendYearsIndex.map(yearIndex => bdeseYear + yearIndex)
}

export const getReportYearOptions = () => {
  const currentYear = new Date().getFullYear()
  return [currentYear + 1, currentYear, currentYear - 1, currentYear - 2].map(
    year => ({
      label: year.toString()
    })
  ) as DateRangeOption[]
}

export const computeTableOfContents = (bdese: Bdese): TableOfContent => {
  return bdese.bdeseSections.map(bdeseSection => ({
    id: bdeseSection.id,
    description: bdeseSection.description,
    titles: [
      {
        sectionId: bdeseSection.id,
        title: formatSectionTitle(bdeseSection.title, bdeseSection.order),
        level: SectionTitleLevel.H1
      },
      ...(
        Array.from(
          parseHTMLFromString(bdeseSection.content).querySelectorAll(
            Object.values(SectionTitleLevel).toString()
          )
        ) as HTMLElement[]
      ).map(element => ({
        sectionId: bdeseSection.id,
        title: element.innerText,
        level: element.tagName as SectionTitleLevel
      }))
    ]
  }))
}

export const generateBdeseSections = async (
  bdeseEmptySections: BdeseBaseSection[],
  bdeseParams: BdeseParams
) => {
  return await Promise.all(
    bdeseEmptySections.map(async emptySection => ({
      ...emptySection,
      content: await getFilledBdeseSectionContent(
        emptySection.content,
        bdeseParams
      )
    }))
  )
}

export const createBdese = async (
  sections: BdeseBaseSection[],
  bdeseParams: BdeseParams
) => {
  const app = useNuxtApp()

  return (
    await app.$apiGqlClient.mutate({
      mutation: CREATE_BDESE_MUTATION,
      variables: {
        sections,
        ...bdeseParams
      }
    })
  ).data.createBdese as Bdese
}

export const generateBdeseSectionFromTemplate = async (
  template: BdeseTemplate,
  templateSectionId: string,
  bdese: Bdese
) => {
  const { $apiGqlClient } = useNuxtApp()
  const section = (
    await $apiGqlClient.query({
      query: GET_BDESE_TEMPLATE_SECTION,
      variables: { template, templateSectionId, isFiscal: bdese.isFiscal }
    })
  ).data.getBdeseTemplateSection as BdeseBaseSection

  return {
    title: section.title,
    description: section.description,
    content: await getFilledBdeseSectionContent(section.content, bdese!)
  }
}

export const getFilledBdeseSectionContent = async (
  content: string,
  bdeseParams: BdeseParams
) => {
  const bdeseContent = parseHTMLFromString(content)

  const [yearTablesToFill, standardTablesToFill, emptyYearTables] = [
    getYearTablesToFill(bdeseContent),
    getStandardTablesToFill(bdeseContent),
    getEmptyYearTables(bdeseContent)
  ]

  const [yearTablesToFillWithQuery, standardTablesToFillWithQuery] = [
    yearTablesToFill.map(table => ({
      table,
      query: updateQueryWithBdeseFilters(
        getQueryFromTable(table),
        bdeseParams.bdeseFilters
      )
    })),
    standardTablesToFill.map(table => ({
      table,
      query: updateQueryWithBdeseFilters(
        getQueryFromTable(table),
        bdeseParams.bdeseFilters
      )
    }))
  ]

  const bdeseDateRange = getBdeseRealizedPeriod(
    bdeseParams.year,
    bdeseParams.realizedYearsNumber,
    bdeseParams.isFiscal
  )

  const bdeseFullPeriod = getPeriodFromDateRange(bdeseDateRange, [])
  const bdeseRealizedYears = getYearsFromDateRange(
    new Date(bdeseDateRange[0]),
    new Date(bdeseDateRange[1]),
    bdeseParams.isFiscal
  )

  const tablesToFillWithNewContent = await Promise.all([
    ...yearTablesToFillWithQuery.map(async ({ table, query }) => ({
      table,
      newTable: getHtmlFromCustomTable(
        addTrendTableData(
          getCustomTableData({
            resultSet: await analyticsGetQuery(
              convertInAnalyticsQuery(
                query,
                bdeseFullPeriod,
                true,
                bdeseParams.isFiscal
              ),
              ScopeType.COMPANY
            ),
            measureTitle: query.measure,
            rowDimensionTitles: query.rowDimensions,
            columnDimensionTitles: [
              bdeseParams.isFiscal
                ? DIMENSION_TITLES.DATE_FISCAL_YEAR
                : DIMENSION_TITLES.DATE_YEAR
            ],
            defaultColumnValues: bdeseRealizedYears
          }),
          bdeseParams
        )
      )
    })),
    ...standardTablesToFillWithQuery.map(async ({ table, query }) => ({
      table,
      newTable: getHtmlFromCustomTable(
        getCustomTableData({
          resultSet: await analyticsGetQuery(
            convertInAnalyticsQuery(
              query,
              bdeseFullPeriod,
              false,
              bdeseParams.isFiscal
            ),
            ScopeType.COMPANY
          ),
          measureTitle: query.measure,
          rowDimensionTitles: query.rowDimensions,
          columnDimensionTitles: query.columnDimension
            ? [query.columnDimension]
            : [],
          defaultColumnValues: null
        })
      )
    }))
  ])

  tablesToFillWithNewContent.forEach(({ table, newTable }) => {
    table.innerHTML = newTable.innerHTML
    const updatedQuery = updateQueryWithBdeseFilters(
      getQueryFromTable(table),
      bdeseParams.bdeseFilters
    )
    table.setAttribute(TABLE_ATTRIBUTES.QUERY, JSON.stringify(updatedQuery))
    table.setAttribute(TABLE_ATTRIBUTES.COMPUTED_AT, formatDate2(new Date()))
  })

  const newEmptyYearTableHtml = createEmptyYearTable(bdeseParams)

  emptyYearTables.forEach(emptyYearTable => {
    emptyYearTable.innerHTML = newEmptyYearTableHtml.innerHTML
  })

  return bdeseContent.body.outerHTML
}

export const createYearTableWithData = (
  bdese: Bdese,
  resultSet: ResultSet,
  measureTitle: string,
  rowDimensionTitles: string[],
  columnDimensionTitles: string[],
  filters: Filter[] = [],
  withTotal: boolean = false
): HTMLTableElement => {
  const bdeseDateRange = getBdeseRealizedPeriod(
    bdese.year,
    bdese.realizedYearsNumber,
    bdese.isFiscal
  )

  const bdeseRealizedYears = getYearsFromDateRange(
    new Date(bdeseDateRange[0]),
    new Date(bdeseDateRange[1]),
    bdese.isFiscal
  )

  const htmlTable = getHtmlFromCustomTable(
    addTrendTableData(
      getCustomTableData({
        resultSet,
        measureTitle,
        rowDimensionTitles,
        columnDimensionTitles,
        defaultColumnValues: bdeseRealizedYears,
        displayTotal: withTotal
      }),
      bdese
    )
  )
  htmlTable.setAttribute(TABLE_ATTRIBUTES.COMPUTED_AT, formatDate2(new Date()))
  htmlTable.setAttribute(TABLE_ATTRIBUTES.YEAR_TABLE_WITH_TREND, 'true')
  const query: BdeseQuery = {
    measure: measureTitle,
    rowDimensions: rowDimensionTitles,
    filters
  }
  htmlTable.setAttribute(TABLE_ATTRIBUTES.QUERY, JSON.stringify(query))
  return htmlTable
}

export const createStandardTableWithData = (
  resultSet: ResultSet,
  measureTitle: string,
  rowDimensionTitles: string[],
  columnDimensionTitles: string[],
  filters: Filter[] = [],
  withTotal: boolean = false,
  customDateRange?: [string, string]
): HTMLTableElement => {
  const htmlTable = getHtmlFromCustomTable(
    getCustomTableData({
      resultSet,
      measureTitle,
      rowDimensionTitles,
      columnDimensionTitles,
      defaultColumnValues: null,
      displayTotal: withTotal
    })
  )
  htmlTable.setAttribute(TABLE_ATTRIBUTES.COMPUTED_AT, formatDate2(new Date()))

  const query: BdeseQuery = {
    measure: measureTitle,
    rowDimensions: rowDimensionTitles,
    columnDimension:
      columnDimensionTitles.length > 0 ? columnDimensionTitles[0] : undefined,
    filters,
    ...(customDateRange ? { dateRange: customDateRange } : {})
  }

  htmlTable.setAttribute(TABLE_ATTRIBUTES.QUERY, JSON.stringify(query))
  return htmlTable
}

export const createChartImage = (
  base64Image: string,
  chartType: ExplorerChartContentType,
  measureTitle: string,
  rowDimensionTitles: string[],
  columnDimensionTitles: string[],
  filters: Filter[] = [],
  customDateRange?: [string, string]
): HTMLImageElement => {
  const htmlImage = document.createElement('img')
  htmlImage.setAttribute('src', base64Image)
  htmlImage.setAttribute(IMAGE_ATTRIBUTES.COMPUTED_AT, formatDate2(new Date()))

  const query: BdeseQuery = {
    measure: measureTitle,
    rowDimensions: rowDimensionTitles,
    columnDimension:
      columnDimensionTitles.length > 0 ? columnDimensionTitles[0] : undefined,
    filters,
    ...(customDateRange ? { dateRange: customDateRange } : {})
  }

  htmlImage.setAttribute(IMAGE_ATTRIBUTES.QUERY, JSON.stringify(query))
  htmlImage.setAttribute(IMAGE_ATTRIBUTES.CHART_TYPE, chartType)
  return htmlImage
}

export const createEmptyYearTable = (
  bdeseParams: BdeseParams
): HTMLTableElement => {
  const realizedYears = getBdeseRealizedYears(
    bdeseParams.year,
    bdeseParams.realizedYearsNumber
  )

  const realizedYearCells = realizedYears.map(realizedYear =>
    getTableCell(
      getFiscalFormat(realizedYear.toString(), bdeseParams.isFiscal),
      [],
      true
    )
  )
  const emptyCells = realizedYears.map(_ => getTableCell())
  const firstEmptyHeaderColumnCell = getTableCell(null, [], true)

  const tableData = {
    headerRows: [[firstEmptyHeaderColumnCell, ...realizedYearCells]],
    bodyRows: [[firstEmptyHeaderColumnCell, ...emptyCells]]
  }

  return getHtmlFromCustomTable(addTrendTableData(tableData, bdeseParams))
}

export const convertInPartialQuery = (
  bdeseQuery: BdeseQuery,
  isYearTableQuery: boolean,
  isFiscal: boolean
): PartialQuery => {
  const yearTableColumnDimension = isFiscal
    ? DIMENSION_TITLES.DATE_FISCAL_YEAR
    : DIMENSION_TITLES.DATE_YEAR

  return {
    measures: [bdeseQuery.measure],
    dimensions: [
      ...bdeseQuery.rowDimensions,
      ...(bdeseQuery.columnDimension && !isYearTableQuery
        ? [bdeseQuery.columnDimension]
        : []),
      ...(isYearTableQuery ? [yearTableColumnDimension] : [])
    ],
    filters: bdeseQuery.filters || []
  }
}

export const createAttachmentZone = (
  attachmentIds: string[],
  updatedAt: string | null = null
) => {
  return `<attachment-zone ${
    ATTACHMENT_ZONE_ATTRIBUTES.ATTACHMENT_IDS
  }=[${attachmentIds.map(id => `"${id}"`).join(',')}] ${
    updatedAt ? `${ATTACHMENT_ZONE_ATTRIBUTES.UPDATED_AT}='${updatedAt}'` : ''
  }'></attachment-zone>`
}

export const convertInAnalyticsQuery = (
  bdeseQuery: BdeseQuery,
  fullPeriod: FullPeriod,
  isYearTableQuery: boolean,
  isFiscal: boolean
): Query => {
  const query = convertInPartialQuery(bdeseQuery, isYearTableQuery, isFiscal)

  return buildCompleteQuery(
    query,
    isYearTableQuery
      ? {
          type: QueryTimeType.MULTI_YEAR_PERIOD
        }
      : {
          type: QueryTimeType.PERIOD
        },
    fullPeriod,
    []
  )
}

const getYearTablesToFill = (bdeseTemplate: Document) => {
  return Array.from(bdeseTemplate.getElementsByTagName('table'))
    .filter(table => table.hasAttribute(TABLE_ATTRIBUTES.QUERY))
    .filter(
      table =>
        table.getAttribute(TABLE_ATTRIBUTES.YEAR_TABLE_WITH_TREND) === 'true'
    )
}

const getStandardTablesToFill = (bdeseTemplate: Document) => {
  return Array.from(bdeseTemplate.getElementsByTagName('table'))
    .filter(table => table.hasAttribute(TABLE_ATTRIBUTES.QUERY))
    .filter(
      table => !table.getAttribute(TABLE_ATTRIBUTES.YEAR_TABLE_WITH_TREND)
    )
}

const getEmptyYearTables = (bdeseTemplate: Document) => {
  return Array.from(bdeseTemplate.getElementsByTagName('table'))
    .filter(table => !table.hasAttribute(TABLE_ATTRIBUTES.QUERY))
    .filter(
      table =>
        table.getAttribute(TABLE_ATTRIBUTES.YEAR_TABLE_WITH_TREND) === 'true'
    )
}

const getQueryFromTable = (htmlTable: HTMLTableElement): BdeseQuery => {
  const query: BdeseQuery = JSON.parse(
    htmlTable.getAttribute(TABLE_ATTRIBUTES.QUERY)!
  )

  return {
    measure: query.measure,
    rowDimensions: query?.rowDimensions || [],
    columnDimension: query?.columnDimension,
    filters: (query?.filters || []) as Filter[]
  }
}

const updateQueryWithBdeseFilters = (
  query: BdeseQuery,
  bdeseFilters: Filter[]
): BdeseQuery => {
  return {
    ...query,
    filters: [
      ...bdeseFilters.map(filter => ({
        member: filter.member,
        operator: filter.operator,
        values: filter.values
      })),
      ...query.filters!
    ]
  }
}

const addTrendTableData = (
  yearTableData: TableData,
  bdeseParams: BdeseParams
): TableData => {
  const { $t } = useNuxtApp()

  const realizedYears = getBdeseRealizedYears(
    bdeseParams.year,
    bdeseParams.realizedYearsNumber
  )

  const trendYears = getBdeseTrendYears(
    bdeseParams.year,
    bdeseParams.trendYearsNumber
  )

  const headerColumnsCount =
    yearTableData.headerRows[0]!.length - realizedYears.length

  const trendHeaderRow = [
    ...(headerColumnsCount > 0
      ? [
          getTableCell(null, undefined, true, {
            colSpan: headerColumnsCount.toString()
          })
        ]
      : []),
    getTableCell(
      $t('legalReports.bdese.editorContent.yearTable.realized'),
      undefined,
      true,
      { colSpan: realizedYears.length.toString() }
    ),
    ...(bdeseParams.trendYearsNumber !== 0
      ? [
          getTableCell(
            $t('legalReports.bdese.editorContent.yearTable.trends'),
            undefined,
            true,
            { colSpan: trendYears.length.toString() }
          )
        ]
      : [])
  ]

  const trendYearCells = trendYears.map(trendYear =>
    getTableCell(
      getFiscalFormat(trendYear.toString(), bdeseParams.isFiscal),
      undefined,
      true
    )
  )
  const emptyCells = trendYears.map(_ => getTableCell())

  if (yearTableData.totalRow) {
    yearTableData.totalRow = yearTableData.totalRow.concat(
      trendYears.map(_ => getTableCell())
    )
  }

  return {
    headerRows: [
      trendHeaderRow,
      yearTableData.headerRows[0]!.concat(trendYearCells)
    ],
    bodyRows: yearTableData.bodyRows.map(bodyRow => bodyRow.concat(emptyCells)),
    totalRow: yearTableData.totalRow
  }
}

export const getMainBdeseFilter = (filters: Filter[]) => {
  return filters.find(
    filter => filter.member === BDESE_SCOPE_MAIN_DIMENSION_TITLE
  )
}

export const getCompanySizeTagTheme = (template: BdeseTemplate) => {
  return template === BdeseTemplate.BASIC_SMALL_COMPANY
    ? TagTheme.PRIMARY
    : TagTheme.QUINARY
}

const removeTableDataBodyRows = (
  tableData: TableData,
  rowIndexesToRemove: number[]
) => {
  return {
    ...tableData,
    bodyRows: tableData.bodyRows.filter(
      (_, index) => !rowIndexesToRemove.includes(index)
    )
  }
}

const removeTableDataColumns = (
  tableData: TableData,
  colIndexesToRemove: number[]
) => {
  let updatedTableData = tableData
  colIndexesToRemove.reverse().forEach(colIndex => {
    updatedTableData = {
      headerRows: updatedTableData.headerRows.map(row =>
        row.toSpliced(colIndex, 1)
      ),
      bodyRows: updatedTableData.bodyRows.map(row =>
        row.toSpliced(colIndex, 1)
      ),
      totalRow: updatedTableData.totalRow?.toSpliced(colIndex, 1)
    }
  })

  return updatedTableData
}

const getObsoleteDimensionValueRowIndexes = (
  tableData: TableData,
  query: BdeseQuery,
  freshData: ResultSetItem[] | undefined,
  rowDimensions: Dimension[]
) => {
  return tableData.bodyRows.reduce((acc, row, rowIndex) => {
    const headerCells = row.slice(0, query.rowDimensions.length)
    const hasObsoleteDimensionValues = headerCells.some((cell, colIndex) => {
      const dimensionsWithValue = cell.dimensionsWithValue![0]
      return (
        dimensionsWithValue &&
        !freshData?.some(
          data =>
            getDimensionValueFromResultSetItem(
              data,
              rowDimensions[colIndex]!
            ) === dimensionsWithValue![1]
        )
      )
    })

    if (hasObsoleteDimensionValues) {
      return [...acc, rowIndex]
    } else {
      return acc
    }
  }, [] as number[])
}

const getObsoleteDimensionValueColIndexes = (
  tableData: TableData,
  freshData: ResultSetItem[] | undefined,
  columnDimension: Dimension
) => {
  return tableData.headerRows[0]!.reduce((acc, cell, colIndex) => {
    const dimensionsWithValue = cell.dimensionsWithValue?.[0]

    if (
      dimensionsWithValue &&
      !freshData?.some(
        data =>
          getDimensionValueFromResultSetItem(data, columnDimension) ===
          dimensionsWithValue[1]
      )
    ) {
      return [...acc, colIndex]
    }
    return acc
  }, [] as number[])
}

const updateBodyTableCell = (
  tableData: TableData,
  tableCell: TableCell,
  rowIndex: number,
  colIndex: number
) => {
  return {
    ...tableData,
    bodyRows: tableData.bodyRows.toSpliced(
      rowIndex,
      1,
      tableData.bodyRows[rowIndex]!.toSpliced(colIndex, 1, tableCell)
    )
  }
}

export const getRefreshedTables = async (
  existingTablesData: (TableData & { isYearTable: boolean })[],
  queries: BdeseQuery[],
  bdese: Bdese,
  shouldRefreshEditedCells: boolean
) => {
  const bdeseDateRange = getBdeseRealizedPeriod(
    bdese.year,
    bdese.realizedYearsNumber,
    bdese.isFiscal
  )

  const bdeseFullPeriod = getPeriodFromDateRange(bdeseDateRange, [])

  const refreshResultSets = await Promise.all(
    queries.map(async (query, index) => {
      return await analyticsGetQuery(
        convertInAnalyticsQuery(
          query,
          query.dateRange
            ? getPeriodFromDateRange(query.dateRange, [])
            : bdeseFullPeriod,
          existingTablesData[index]!.isYearTable,
          bdese.isFiscal
        ),
        ScopeType.COMPANY
      )
    })
  )
  if (
    refreshResultSets.some(resultSet =>
      resultSet.results.some(result => !result.data)
    )
  )
    throw Error

  return existingTablesData.map((existingTableData, index) => {
    const query = queries[index]!
    const isYearTable = existingTableData.isYearTable

    const measure = getMeasureFromTitle(query.measure)

    const mainCube = getMainCubeFromQuery({ measures: [query.measure] })

    const rowDimensions = query.rowDimensions.length
      ? query.rowDimensions.map(
          dimensionTitle =>
            getDimensionFromTitleAndMainCube(dimensionTitle, mainCube!)!
        )
      : []

    const yearTableColumnDimension = bdese.isFiscal
      ? DIMENSION_TITLES.DATE_FISCAL_YEAR
      : DIMENSION_TITLES.DATE_YEAR

    const columnDimensionTitle = isYearTable
      ? yearTableColumnDimension
      : query.columnDimension

    const columnDimension =
      columnDimensionTitle &&
      getDimensionFromTitleAndMainCube(columnDimensionTitle, mainCube!)

    const columnDimensions = columnDimension ? [columnDimension] : []

    const freshData = processResultSetForTableData(
      refreshResultSets[index]!,
      measure,
      [...rowDimensions, ...columnDimensions]
    )

    // todo - check if headerRows structure is not modified - yearTable, simple table and table without column dimension
    const yearTableFirstHeaderRow = isYearTable
      ? existingTableData.headerRows[0]
      : undefined

    let cleanedTableData: TableData = isYearTable
      ? {
          ...existingTableData,
          headerRows: existingTableData.headerRows.toSpliced(0, 1)
        }
      : existingTableData

    // remove obsolete dimension value columns or rows
    if (query.rowDimensions.length > 0) {
      const rowIndexesToRemove = getObsoleteDimensionValueRowIndexes(
        cleanedTableData,
        query,
        freshData,
        rowDimensions
      )

      cleanedTableData = removeTableDataBodyRows(
        cleanedTableData,
        rowIndexesToRemove
      )
    }

    if (query.columnDimension) {
      const colIndexesToRemove = getObsoleteDimensionValueColIndexes(
        cleanedTableData,
        freshData,
        columnDimensions[0]!
      )

      cleanedTableData = removeTableDataColumns(
        cleanedTableData,
        colIndexesToRemove
      )
    }

    let refreshedTableData: TableData = cleanedTableData

    // update body cells
    cleanedTableData.bodyRows.forEach((row, rowIndex) => {
      row.forEach((cell, colIndex) => {
        if (!cell.isHeader && cell.attributes?.initialValue) {
          const refreshedValue = freshData?.find(item =>
            cell.dimensionsWithValue?.every(
              ([dimension, value]) =>
                getDimensionValueFromResultSetItem(item, dimension) === value
            )
          )?.[measure.name]

          const isInitialValue =
            cell?.content === cell?.attributes?.initialValue

          const value = formatMemberValue(
            refreshedValue!,
            measure.meta.listAndKpiFormat || measure.meta.format,
            FormatContext.KPI,
            false
          )

          const refreshedCell = {
            ...cell,
            content:
              shouldRefreshEditedCells || isInitialValue
                ? value
                : cell.content!,
            attributes: {
              ...cell.attributes,
              initialValue: value
            }
          }

          refreshedTableData = updateBodyTableCell(
            refreshedTableData,
            refreshedCell,
            rowIndex,
            colIndex
          )
        }
      })
    })

    // add new dimension value rows and cols
    let updatedTableData: TableData = refreshedTableData

    if (rowDimensions.length > 0 || columnDimensions.length > 0) {
      const existingRowDimensionValues = refreshedTableData.bodyRows.map(row =>
        row
          .slice(0, query.rowDimensions.length)
          .map(cell => cell.dimensionsWithValue?.[0]?.[1])
      )

      const freshRowDimensionValues = getRowsWithDimensionValues(
        freshData,
        rowDimensions
      )

      const newRowDimensionValues = freshRowDimensionValues.filter(
        freshValues =>
          !existingRowDimensionValues.some(existingValues =>
            existingValues.every(
              (existingValue, index) => freshValues[index] === existingValue
            )
          )
      )

      let existingColumnDimensionValues: (DimensionValue | undefined)[]
      let newColumnDimensionValues: DimensionValue[]

      if (columnDimensions.length > 0) {
        existingColumnDimensionValues = updatedTableData.headerRows[0]!.slice(
          query.rowDimensions.length,
          updatedTableData.headerRows[0]?.length
        ).map(cell => cell.dimensionsWithValue?.[0]?.[1])

        const defaultColumnValues = getDefaultColumnValue(
          existingTableData.isYearTable,
          bdeseDateRange
        )

        const freshColumnDimensionValues =
          columnDimensions.length > 0
            ? getColumnValues(
                columnDimensions[0],
                freshData,
                defaultColumnValues
              )
            : []

        newColumnDimensionValues = freshColumnDimensionValues.filter(
          freshValue => !existingColumnDimensionValues.includes(freshValue)
        )
      } else {
        existingColumnDimensionValues = []
        newColumnDimensionValues = []
      }

      const newRows = buildBodyRowsData({
        rowsWithDimensionValues: newRowDimensionValues,
        rowDimensions,
        mainHeaderRow: updatedTableData.headerRows?.[0] || [],
        columnDimensions,
        measure,
        resultsData: freshData
      })

      updatedTableData = {
        ...updatedTableData,
        bodyRows: [...updatedTableData.bodyRows, ...newRows]
      }

      if (newColumnDimensionValues.length > 0) {
        updatedTableData = addColumnsData(
          updatedTableData,
          newColumnDimensionValues,
          columnDimensions,
          rowDimensions,
          measure,
          freshData
        )
      }

      if (newRowDimensionValues.length || newColumnDimensionValues.length) {
        updatedTableData = sortTableData(
          updatedTableData,
          rowDimensions,
          columnDimensions
        )
      }

      if (updatedTableData.totalRow) {
        // TODO - decide what to do
      }

      if (isYearTable) {
        updatedTableData = {
          ...updatedTableData,
          headerRows: [yearTableFirstHeaderRow!, ...updatedTableData.headerRows]
        }
      }
    }

    // create HTML and set attributes
    const refreshedHtmlTable = getHtmlFromCustomTable({
      ...updatedTableData,
      bodyRows: removeEmptyBodyRows(updatedTableData.bodyRows)
    })

    refreshedHtmlTable.setAttribute(
      TABLE_ATTRIBUTES.QUERY,
      JSON.stringify(query)
    )

    refreshedHtmlTable.setAttribute(
      TABLE_ATTRIBUTES.COMPUTED_AT,
      formatDate2(new Date())
    )

    if (isYearTable) {
      refreshedHtmlTable.setAttribute(
        TABLE_ATTRIBUTES.YEAR_TABLE_WITH_TREND,
        'true'
      )
    }

    return refreshedHtmlTable
  })
}

const compareTableCellContent = (a: TableCell, b: TableCell) => {
  return compareValuesAlphabetically(getContent(a), getContent(b))
}

const compareHeaderCellValue = (a: TableCell, b: TableCell) => {
  return compareValuesAlphabetically(
    getHeaderCellValue(a),
    getHeaderCellValue(b)
  )
}

const reOrderByIndex = <T>(array: T[], orderedIndexes: number[]): T[] => {
  return orderedIndexes.map(i => array[i]!)
}

const getHeaderCellValue = (cell: TableCell): string | null => {
  return cell.dimensionsWithValue?.[0]?.[1]?.toString() || null
}

const sortTableDataRows = (
  tableData: TableData,
  rowDimensions: Dimension[]
) => {
  if (rowDimensions.length > 0) {
    tableData.bodyRows.sort((rowA: TableCell[], rowB: TableCell[]) => {
      // if user-created rows, there is no row dimension value (special case)
      if (!getHeaderCellValue(rowA[0]!) && !getHeaderCellValue(rowB[0]!)) {
        return compareTableCellContent(rowA[0]!, rowB[0]!)
      }

      // if Reflect-created rows, sort rows according to their row dimension values
      if (getHeaderCellValue(rowA[0]!) !== getHeaderCellValue(rowB[0]!)) {
        return compareHeaderCellValue(rowA[0]!, rowB[0]!)
        // if first column header dimension values are equal, check second column values (if it exists)
      } else if (rowDimensions.length > 1) {
        return compareHeaderCellValue(rowA[1]!, rowB[1]!)
      } else {
        return 0
      }
    })
  }

  return tableData
}

const sortTableDataColumns = (
  tableData: TableData,
  rowDimensions: Dimension[],
  columnDimensions: Dimension[]
) => {
  if (columnDimensions.length > 0) {
    const referenceHeaderRow = tableData.headerRows[0]!

    const orderedInd = [...Array(referenceHeaderRow.length).keys()].sort(
      (a, b) => {
        if (a < rowDimensions.length || b < rowDimensions.length) return 0
        return compareHeaderCellValue(
          referenceHeaderRow[a]!,
          referenceHeaderRow[b]!
        )
      }
    )

    tableData.headerRows = [reOrderByIndex(referenceHeaderRow, orderedInd)]
    tableData.bodyRows = tableData.bodyRows.map(row =>
      reOrderByIndex(row, orderedInd)
    )

    tableData.totalRow =
      tableData.totalRow && reOrderByIndex(tableData.totalRow, orderedInd)
  }

  return tableData
}

export const sortTableData = (
  tableData: TableData,
  rowDimensions: Dimension[],
  columnDimensions: Dimension[]
) => {
  const rowSortedTableData = sortTableDataRows(tableData, rowDimensions)
  return sortTableDataColumns(
    rowSortedTableData,
    rowDimensions,
    columnDimensions
  )
}
