import { gql } from 'graphql-tag'
import { BDESE_FRAGMENT } from './bdeseFragment'

export const PUBLISH_BDESE_MUTATION = gql`
  ${BDESE_FRAGMENT}
  mutation PUBLISH_BDESE_MUTATION(
    $id: String!
    $publicationNote: String
    $withEmailNotification: Boolean!
  ) {
    publishBdese(
      publicationNote: $publicationNote
      id: $id
      withEmailNotification: $withEmailNotification
    ) {
      ...BdeseFields
    }
  }
`
