import { gql } from 'graphql-tag'
import { BDESE_FRAGMENT } from './bdeseFragment'

export const REMOVE_BDESE_SECTION_MUTATION = gql`
  ${BDESE_FRAGMENT}
  mutation REMOVE_BDESE_SECTION_MUTATION($id: String!, $sectionId: String!) {
    removeBdeseSection(id: $id, sectionId: $sectionId) {
      ...BdeseFields
    }
  }
`
