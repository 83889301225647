import { useFiltersStore } from '~/stores/filters'
import {
  buildCompletePeriodFromPeriodOption,
  getPeriodFromDateRange,
  isPeriodInvalid,
  getPeriodOptions
} from '~/services/period'
import {
  type FullPeriod,
  type Period,
  PeriodType,
  TimeGrain,
  TimeDimensionStoreContext
} from '~/types/timeDimension'
import { replaceRouteQueryParam } from '~/services/router'
import { QUERY_PARAMETERS } from '~/types/queryParameters'
import { PERIOD_ID } from '~/constants/period'
import { formatDate2 } from '~/services/date'

interface State {
  selectedPeriod: FullPeriod
  options: Period[]
  context: TimeDimensionStoreContext
}

export const useTimeDimensionStore = defineStore('timeDimension', {
  state: (): State => {
    return {
      selectedPeriod: {
        periodId: PERIOD_ID.QTD,
        periodType: PeriodType.QUARTER,
        dateRange: [],
        isFuture: false,
        compareDateRange: [],
        trendDateRange: [],
        trendSubPeriod: TimeGrain.MONTH,
        chartSubPeriod: TimeGrain.WEEK
      },
      options: [],
      context: TimeDimensionStoreContext.DASHBOARD
    }
  },
  actions: {
    async setSelectedPeriodFromPeriodId({
      periodId = undefined,
      shouldModifyUrlParams = true
    }: {
      periodId?: string | undefined
      shouldModifyUrlParams?: boolean
    }) {
      periodId = periodId || this.getDefaultPeriodId()

      const periodOption = this.options.find(
        periodOption => periodOption.periodId === periodId
      )!

      const newPeriod = buildCompletePeriodFromPeriodOption(periodOption)
      await this.updateSelectedPeriod(newPeriod, shouldModifyUrlParams)
    },
    async setSelectedPeriodFromDateRange({
      dateRange,
      shouldModifyUrlParams = true
    }: {
      dateRange: string[]
      shouldModifyUrlParams?: boolean
    }) {
      const newPeriod = getPeriodFromDateRange(dateRange, this.options)
      await this.updateSelectedPeriod(newPeriod, shouldModifyUrlParams)
    },
    async generateSelectedDateRange(
      context: TimeDimensionStoreContext,
      shouldModifyUrlParams: boolean = true
    ) {
      const route = useRoute()

      this.context = context
      this.options = getPeriodOptions(context)

      if (
        !route.query[QUERY_PARAMETERS.START_DATE] ||
        !route.query[QUERY_PARAMETERS.END_DATE] ||
        isPeriodInvalid(route.query.start as string, route.query.end as string)
      ) {
        await this.setSelectedPeriodFromPeriodId({
          periodId: undefined,
          shouldModifyUrlParams
        })
        return
      }

      const startDate = formatDate2(route.query.start as string)
      const endDate = formatDate2(route.query.end as string)

      await this.setSelectedPeriodFromDateRange({
        dateRange: [startDate, endDate],
        shouldModifyUrlParams: false
      })
    },
    getDefaultPeriodId() {
      switch (this.context) {
        case TimeDimensionStoreContext.SETTINGS:
          return PERIOD_ID.ALL_TIME
        case TimeDimensionStoreContext.BDESE:
          return PERIOD_ID.YTD
        case TimeDimensionStoreContext.DASHBOARD: {
          return PERIOD_ID.QTD
        }
      }
    },
    async updateSelectedPeriod(
      period: FullPeriod,
      shouldModifyUrlParams: boolean
    ) {
      shouldModifyUrlParams &&
        (await replaceRouteQueryParam([
          [QUERY_PARAMETERS.START_DATE, period.dateRange[0]],
          [QUERY_PARAMETERS.END_DATE, period.dateRange[1]]
        ]))

      this.selectedPeriod = period
      if (this.context !== TimeDimensionStoreContext.SETTINGS) {
        const filtersStore = useFiltersStore()
        await filtersStore.getStandardFiltersOptions()
      }
    }
  }
})
