import { gql } from 'graphql-tag'
import { BDESE_FRAGMENT } from './bdeseFragment'

export const SORT_BDESE_SECTIONS_MUTATION = gql`
  ${BDESE_FRAGMENT}
  mutation SORT_BDESE_SECTIONS_MUTATION(
    $id: String!
    $sortedSectionIds: [String!]!
  ) {
    sortBdeseSections(id: $id, sortedSectionIds: $sortedSectionIds) {
      ...BdeseFields
    }
  }
`
