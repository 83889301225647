import { gql } from 'graphql-tag'

export const GET_BDESE_TEMPLATE_SECTION = gql`
  query GET_BDESE_TEMPLATE_SECTION(
    $template: BdeseTemplate!
    $templateSectionId: String!
    $isFiscal: Boolean!
  ) {
    getBdeseTemplateSection(
      template: $template
      templateSectionId: $templateSectionId
      isFiscal: $isFiscal
    ) {
      content
      title
      description
    }
  }
`
